body{
  overflow: hidden;
}

.ant-select-dropdown {
   z-index: 2000 !important;
 }


 /* Works on Chrome, Edge, and Safari */
::-webkit-scrollbar {
  width: 14px;               /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background: #f0f0f0;        /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
  background-color: #888;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 3px solid #f0f0f0; /* creates padding around scroll thumb */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;    /* color of the scroll thumb when hovering */
}

/* Works on Firefox */
* {
  scrollbar-width: thin;          /* "auto" or "thin"  */
  scrollbar-color: #888 #f0f0f0;  /* scroll thumb and track */
}
